@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;600&display=swap');
@import './assets/styles/normalize';
@import './assets/styles/variables';

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

body {
  margin: 0;
  font: 2rem/1.4 'Rajdhani', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $lightColor;
  
  font-weight: 400;
  color: $darkColor;
  min-height: 100vh;
}