@import './assets/styles/variables';

.wind {
  position: relative;

  &__back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    &__img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }

  &:after {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: $lightColor;
    transform: translateY(-95%);
    z-index: 5;
  }
}

.wind-header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: $lightColor;

  &__logo {
    height: 3.5rem;
  }
}

.wind-content {
  padding-top: 5.5rem;
  padding-bottom: 2rem;
}

.wind-reload {
  position: fixed;
  z-index: 10;
  right: 3rem;
  bottom: 3rem;
  padding: 0;
  line-height: 1;
  height: 4rem;
  width: 4rem;
  border-radius: 2rem;
  background: transparent;
  border: none;
    filter: drop-shadow(0 .4rem .8rem rgba(0, 0, 0, .25));

  img {
    width: 4rem;
    height: 4rem;
  }
}

.App-link, b {
  color: #C6668E;
}