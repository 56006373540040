@import '../assets/styles/variables';
@import '../assets/styles/fonts';

.station {
    margin: 1.5rem;
    padding: 2rem;
    border-radius: .6rem;
    box-shadow: 0 .8rem 1.6rem rgba(0, 0, 0, .1);
    font-weight: 600;
    color: #333;
    background-color: rgba(251, 247,242, .85);
    backdrop-filter: blur(5px);

    &__header {
        position: relative;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(43, 39, 34, .5);

        span {
            position: absolute;
            right: 0;
            bottom: 1rem;
            font-size: 1.2rem;
            opacity: .5;
        }
    }

    &__info {
        display: flex;
        flex-wrap: wrap;

        &__data {
            padding: 1rem 1rem 0 0;
            width: calc(100% / 3);
            line-height: 1;

            div {
                padding-top: .4rem;
            }
            sup {
                font-size: 60%;
            }

            &.alert {
                div {
                    color: $alert;

                    sup {
                        color: $darkColor;
                    }
                }
            }
        }
    }

    &.alert {
        border: 1px solid $alert;
    }

    &.loading-animation {
        .station__info {
            &__data {
                div {
                    position: relative;

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        z-index: 10;
                        top: .2rem;
                        left: 0;
                        height: 2rem;
                        width: 6rem;
                        background: linear-gradient(45deg, rgba(251,247,242,1) 0%, rgba(235,231,226,1) 100%);
                        background-size: 200%;
                        animation: loader .75s infinite;
                    }
                }
            }
        }
    }
    &__footer {
        padding-top: 1rem;
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
    }
}

@keyframes loader {
  0% { background-position: 0% 50%; }
  25% { background-position: 50% 50%; }
  50% { background-position: 100% 50%; }
  75% { background-position: 50% 50%; }
  100% { background-position: 0% 50%; }
}