@import './variables';

h1 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    line-height: 1;
    font-weight: 600;
}

h2 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1;
    font-weight: 600;
    color: $ligthDarkColor;
}